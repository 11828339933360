import {ArrowDown2, TableDocument} from "iconsax-react";
import {Link} from "react-router-dom";
import React, {useRef, useState} from "react";
import {ReactComponent as UploadIcon} from '../../Assets/Icons/uploadIcon.svg';

function OnboardingKyc({next, proceed}: any) {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('')
    const [error, setError] = useState("");
    const fileInputRef = useRef<any>(null); // Use ref to reference the hidden input

    const handleFileChange = (e: any) => {
        const selectedFile = e.target.files[0];
        console.log(file)
        const fileSizeLimit = 10 * 1024 * 1024; // 10MB in bytes

        if (selectedFile.size > fileSizeLimit) {
            setError("File too large. Maximum size is 10MB.");
            setFile(null);
        } else {
            setError("");
            setFile(selectedFile);
            setFileName(selectedFile.name)
        }
    };


    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    return (
        <>
            <div
                className={`${next === 'kyc' ? '' : 'hidden'} bg-white rounded-xl md:h-3/4 xl:h-fit md:w-6/12 lg:w-5/12 xl:w-4/12 w-11/12 overflow-auto border border-input-color my-8`}
                style={{boxShadow: '0px 2px 8px 2px #8C8C8C1A'}}>

                <form autoComplete="off" className='p-7 bg-white' >
                    <div className='flex gap-1.5 items-center'>
                        <h3 className='font-semibold text-2xl'>
                            KYC
                        </h3>
                        <p className='font-semibold text-gray-400'>Verification</p>
                    </div>
                    <div className='py-5'>
                        <label className='font-semibold text-sm block pb-2'>
                            Select Document Type
                        </label>
                        <div className="relative">
                            <TableDocument size="32" color="#CCCCCC" variant="Bold"
                                           className="absolute w-4 h-4 text-gray-500 left-4 top-1/2 transform -translate-y-1/2"/>
                            <select
                                className="focus-visible:outline-0 custom-select text-xs w-full p-2 py-5 pl-10 border border-input-color rounded-custom"
                            >
                                <option defaultValue='' disabled>
                                    Select Document Type
                                </option>
                            </select>
                            <ArrowDown2 size="32" color="#000000" variant="Bold"
                                        className="absolute w-4 h-4 text-gray-500 right-4 top-1/2 transform -translate-y-1/2"/>
                        </div>
                    </div>
                    <div className='pb-5'>
                        <div
                            className="flex gap-3.5 justify-evenly items-center border-dashed border rounded-lg border-black py-4 px-2">
                            <UploadIcon/>
                            <div>
                                <p className={``}>Upload ID Image (Valid)<span className={`text-red-600`}>*</span></p>
                                <p className={`text-gray-400 text-xxs`}>{!fileName ? 'JPG, PNG or PDF, file size no more than 10MB' : fileName}</p>
                            </div>
                            <button onClick={handleButtonClick} type={`button`}
                                    className={` uppercase bg-custom-button-green text-custom-lemongreen border border-custom-lemongreen rounded-lg px-4 text-xs py-2.5`}>
                                Select File
                            </button>
                            <input ref={fileInputRef} name={`upload`} onChange={handleFileChange} required
                                   type="file" hidden/>
                        </div>
                        {error && <p className="text-red-600 text-xs pt-1.5">{error}</p>}
                    </div>
                    <div className='flex justify-center'>
                        <button onClick={() => proceed('cac')}
                            className='w-6/12 bg-custom-darkgreen text-white font-semibold rounded-custom py-2'>
                            Proceed
                        </button>
                    </div>
                    <div className='text-center py-3 text-xs'>
                        <p>Already have an account? <Link to='/login'
                                                          className='font-semibold text-custom-green'>Login</Link></p>
                    </div>
                </form>
            </div>
        </>
    )
}
export default OnboardingKyc
