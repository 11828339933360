import React from 'react'
import Navbar from "./Navbar";
import {Route, Routes, useNavigate} from "react-router-dom";
import LandingPage from "../../Pages/LandingPages/landingPage";
import Register from "../../Pages/Authentication/Register";
import Login from "../../Pages/Authentication/Login";
import CoownRegister from "../../Pages/Authentication/CoownRegister";
import Forgotpassword from "../../Pages/Authentication/Forgetpassword";
import About from "../../Pages/LandingPages/About";
import Blog from "../../Pages/LandingPages/Blog";
import SingleBlog from "../../Pages/LandingPages/SingleBlog";
import Property from "../../Pages/Dashboard/Property";
import CoporateSignUp from "../SignUp/Lister/Cooperate";
import PersonalSignUp from "../SignUp/Lister/Personal";

function Layout() {
    const navigate = useNavigate()

    // Function to handle the scroll action
    const scrollToDiv =  () => {
        // console.log('i reach here')
        navigate('/', { state: { scrollTo: 'targetDiv' } });
    };
    return (
        <div>
            <Navbar onContact={scrollToDiv} />
            <div>
                <Routes>
                    <Route index element={<LandingPage />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/register/co-own' element={<CoownRegister />} />
                    <Route path='/register/personal' element={<PersonalSignUp />} />
                    <Route path='/register/cooperate' element={<CoporateSignUp />} />
                    <Route path='/forgotpassword' element={<Forgotpassword />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/property' element={<Property />} />
                    <Route path='/blog' element={<Blog />} />
                    <Route path='/blog/:id' element={<SingleBlog />} />
                </Routes>
            </div>
        </div>
    )
}
export default Layout
