import {Link} from "react-router-dom";
import React, {useRef, useState} from "react";
import {ReactComponent as UploadIcon} from '../../Assets/Icons/uploadIcon.svg';

function CacVerification({next, proceed}: any) {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('')
    const [error, setError] = useState("");
    const fileInputRef = useRef<any>(null); // Use ref to reference the hidden input

    const handleFileChange = (e: any) => {
        const selectedFile = e.target.files[0];
        console.log(file)
        const fileSizeLimit = 10 * 1024 * 1024; // 10MB in bytes

        if (selectedFile.size > fileSizeLimit) {
            setError("File too large. Maximum size is 10MB.");
            setFile(null);
        } else {
            setError("");
            setFile(selectedFile);
            setFileName(selectedFile.name)
        }
    };


    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    return (
        <>
            <div
                className={`${next === 'cac' ? '' : 'hidden'} bg-white rounded-xl md:h-3/4 xl:h-fit md:w-6/12 lg:w-5/12 xl:w-4/12 w-11/12 overflow-auto border border-input-color my-8`}
                style={{boxShadow: '0px 2px 8px 2px #8C8C8C1A'}}>

                <form autoComplete="off" className='p-7 bg-white'>
                    <div className='flex gap-1.5 items-center'>
                        <h3 className='font-semibold text-2xl'>
                            CAC
                        </h3>
                        <p className='font-semibold text-gray-400'>Verification</p>
                    </div>
                    <div className='py-3'>
                        <div
                            className="flex gap-3.5 justify-evenly items-center border-dashed border rounded-lg border-black py-4 px-2">
                            <UploadIcon/>
                            <div>
                                <p className={``}>Upload CAC Image (Valid)<span className={`text-red-600`}>*</span></p>
                                <p className={`text-gray-400 text-xxs`}>{!fileName ? 'JPG, PNG or PDF, file size no more than 10MB' : fileName}</p>
                            </div>
                            <button onClick={handleButtonClick} type={`button`}
                                    className={` uppercase bg-custom-button-green text-custom-lemongreen border border-custom-lemongreen rounded-lg px-4 text-xs py-2.5`}>
                                Select File
                            </button>
                            <input ref={fileInputRef} name={`upload`} onChange={handleFileChange} required
                                   type="file" hidden/>
                        </div>
                        {error && <p className="text-red-600 text-xs pt-1.5">{error}</p>}
                    </div>
                    <div className='pb-3'>
                        <div
                            className="flex gap-3.5 justify-evenly items-center border-dashed border rounded-lg border-black py-4 px-2">
                            <UploadIcon/>
                            <div>
                                <p className={``}>Upload Utility Bill Image (Valid)<span className={`text-red-600`}>*</span></p>
                                <p className={`text-gray-400 text-xxs`}>{!fileName ? 'JPG, PNG or PDF, file size no more than 10MB' : fileName}</p>
                            </div>
                            <button onClick={handleButtonClick} type={`button`}
                                    className={` uppercase bg-custom-button-green text-custom-lemongreen border border-custom-lemongreen rounded-lg px-4 text-xs py-2.5`}>
                                Select File
                            </button>
                            <input ref={fileInputRef} name={`upload`} onChange={handleFileChange} required
                                   type="file" hidden/>
                        </div>
                        {error && <p className="text-red-600 text-xs pt-1.5">{error}</p>}
                    </div>
                    <div className={`pb-5`}>
                        <label className={`text-sm pb-2 block`}>
                            Company’s TIN number
                        </label>
                        <input placeholder={`Company’s TIN number`} type={`text`} className={` py-2 px-4 border border-custom focus-visible:outline-0 rounded w-full`} />
                    </div>
                    <div className='flex justify-center'>
                        <button onClick={() => proceed('otp')}
                                className='w-6/12 bg-custom-darkgreen text-white font-semibold rounded-custom py-2'>
                            Proceed
                        </button>
                    </div>
                    <div className='text-center py-3 text-xs'>
                        <p>Already have an account? <Link to='/login'
                                                          className='font-semibold text-custom-green'>Login</Link></p>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CacVerification
