import React from 'react'
// import Navbar from "../Components/Layout/Navbar";
import ForgotpasswordForm from "../../Components/SignUp/ForgotpasswordForm";


function Forgotpassword() {
    return (
        <div>
            {/*<Navbar />*/}
            <ForgotpasswordForm />
        </div>
    )
}

export default Forgotpassword