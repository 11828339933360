import React from 'react';
import './App.css';
import {Route, Routes, BrowserRouter} from 'react-router-dom'
import Layout from "./Components/Dashboard/Layout";
import HomeLayout from "./Components/Layout/Layout";
import {ProtectedRoutes} from "./Hooks/ProtectedRoutes";
import {ToastProvider} from "./Store/NotificationContext";



function App() {
  return (
    <div className="App">
      <BrowserRouter >
        <ToastProvider>
          <Routes>
            <Route path='/*' element={<HomeLayout />} />
            <Route element={<ProtectedRoutes />}>
              <Route path='/app/*' element={<Layout />} />
            </Route>
          </Routes>
        </ToastProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
