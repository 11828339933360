import React from 'react'
import  {ReactComponent as CustomerChat} from '../../Assets/Icons/customerchat.svg'
import  {ReactComponent as ArrowDown} from '../../Assets/Icons/arrowdown.svg'
import Review from '../../Assets/Images/femaleuser.svg'
import MaleReview from '../../Assets/Images/maleuser.svg'

function Customer() {
    return (
        <div>
            <div className='text-center'>
                <h1 className='text-2xl md:text-4xl font-bold text-black pb-3.5'>
                    From our happy customers
                </h1>
                {/*<p className='text-custom text-xs'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sed tristique metus proin id lorem odio</p>*/}
            </div>
            <div className='flex-col md:flex-row w-10/12 lg:w-10/12 md:w-11/12 xl:w-8/12 mx-auto md:flex gap-7 py-12 justify-center'>
                <div className='flex flex-col items-center justify-center md:w-4/12'>
                    <div className='customerCard flex flex-col items-center justify-center text-center p-6'>
                        <CustomerChat/>
                        <p className='italic text-sm py-4'>
                            “ Kekere is a very reliable platform/company, I was able to purchase a property in Nigeria with ease and got the documents in no time.”
                        </p>
                    </div>
                    <ArrowDown/>
                    <div className='flex flex-col justify-center items-center py-2'>
                        <img src={Review} alt='testimonial' className='max-w-min py-4'/>
                        <p className='text-sm font-semibold text-black'>Miss Adebukola Araloye</p>
                        <p className='text-custom text-sm'>Product Manager/Scrum master</p>
                    </div>
                </div>
                <div className='flex flex-col items-center justify-center md:w-4/12'>
                    <div className='customerCard flex flex-col items-center justify-center text-center p-6'>
                        <CustomerChat/>
                        <p className='italic text-sm py-4'>
                            “ I am glad I came across the kekere website while surfing the internet. I am now a happy property owner. Thank you kekere.”
                        </p>
                    </div>
                    <ArrowDown/>
                    <div className='flex flex-col justify-center items-center py-2'>
                        <img src={MaleReview} alt='testimonial' className='max-w-min py-4'/>
                        <p className='text-sm font-semibold text-black'>Mr keshinton tola</p>
                        <p className='text-custom text-sm'>Head Of Client Service</p>
                    </div>
                </div>
                <div className='flex flex-col items-center justify-center md:w-4/12'>
                    <div className='customerCard flex flex-col items-center justify-center text-center p-6'>
                        <CustomerChat/>
                        <p className='italic text-sm py-4'>
                            “ Kekere is the best platform for listing properties as a landlord or agent ”
                        </p>
                    </div>
                    <ArrowDown/>
                    <div className='flex flex-col justify-center items-center py-2'>
                        <img src={MaleReview} alt='testimonial' className='max-w-min py-4'/>
                        <p className='text-sm font-semibold text-black'>Mr phillip oscar</p>
                        <p className='text-custom text-sm'>Software Engineer</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Customer