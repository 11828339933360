import {useUserAuthService} from "../Services/authservices";
import {useState} from "react";
import {useAuth} from "../Store/AuthContext";
import {useToast} from "../Store/NotificationContext";
import {useNavigate} from "react-router-dom";

// interface Register {
//     name: string;
//     email: string;
//     password: string;
//     re_password: string;
//     phone: string;
// }

export const useRegister = () => {
    const {dispatchLogin} =useAuth()
    const {postRegister} = useUserAuthService()
    const { showToast } = useToast();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const register = async (payload: {
        password: string;
        phone: string;
        password_confirmation: string;
        email: string;
        username: string
    }) => {
        if (isLoading) {
            return false;
        }

        // const payload = {
        //     "username": "dolo7448331145644h",
        //     "email" : "jolodo44498553399ek@kekere.com",
        //     "password" : "esddffffgg",
        //     "password_confirmation" : "esddffffgg"
        // }

        let userResponse = null;
        setIsLoading(true)

        try {
            const response = await postRegister(payload);
            console.log(response);
            userResponse = response?.data?.data;
            if (userResponse) {
                const { access_token } = userResponse;
                dispatchLogin(access_token)
                navigate('/login')
                showToast('Success', 'This is a success toast notification', 'success');
            }


        } catch (e: any) {
            console.log(e.response.data.error)
            setError(e)
            showToast('Error', e.response.data.error, 'error');

        } finally {
            setIsLoading(false);
        }

        return userResponse;
    };

    return {
        register,
        isLoading,
        error
    }
}
