import React from 'react';
import propertyImage from "../../Assets/Images/propertyImage.png";
import {Link} from "react-router-dom";
import Footer from "../../Components/Layout/Footer";

function  Property(){
    const Investment = [
        {
            id: 1,
            title: 'Co-owned House',
            totalPrice: '₦100,000,000',
            noOfSlotAvailable: 0,
            totalNumberOfSlot: 9,
            priceOfSlot: '₦20,000,000',
            annualEarning: '₦2,000,000'
        },
        {
            id: 2,
            title: 'Co-owned House',
            totalPrice: '₦100,000,000',
            noOfSlotAvailable: 3,
            totalNumberOfSlot: 9,
            priceOfSlot: '₦20,000,000',
            annualEarning: '₦2,000,000'
        },
        {
            id: 3,
            title: 'Co-owned House',
            totalPrice: '₦100,000,000',
            noOfSlotAvailable: 5,
            totalNumberOfSlot: 9,
            priceOfSlot: '₦20,000,000',
            annualEarning: '₦2,000,000'
        },
        {
            id: 4,
            title: 'Co-owned House',
            totalPrice: '₦100,000,000',
            noOfSlotAvailable: 0,
            totalNumberOfSlot: 9,
            priceOfSlot: '₦20,000,000',
            annualEarning: '₦2,000,000'
        },
        {
            id: 5,
            title: 'Co-owned House',
            totalPrice: '₦100,000,000',
            noOfSlotAvailable: 0,
            totalNumberOfSlot: 9,
            priceOfSlot: '₦20,000,000',
            annualEarning: '₦2,000,000'
        },
        {
            id: 6,
            title: 'Co-owned House',
            totalPrice: '₦100,000,000',
            noOfSlotAvailable: 0,
            totalNumberOfSlot: 9,
            priceOfSlot: '₦20,000,000',
            annualEarning: '₦2,000,000'
        },
        {
            id: 7,
            title: 'Co-owned House',
            totalPrice: '₦100,000,000',
            noOfSlotAvailable: 6,
            totalNumberOfSlot: 9,
            priceOfSlot: '₦20,000,000',
            annualEarning: '₦2,000,000'
        },
        {
            id: 8,
            title: 'Co-owned House',
            totalPrice: '₦100,000,000',
            noOfSlotAvailable: 3,
            totalNumberOfSlot: 9,
            priceOfSlot: '₦20,000,000',
            annualEarning: '₦2,000,000'
        },
        {
            id: 1,
            title: 'Co-owned House',
            totalPrice: '₦100,000,000',
            noOfSlotAvailable: 0,
            totalNumberOfSlot: 9,
            priceOfSlot: '₦20,000,000',
            annualEarning: '₦2,000,000'
        },
        {
            id: 2,
            title: 'Co-owned House',
            totalPrice: '₦100,000,000',
            noOfSlotAvailable: 3,
            totalNumberOfSlot: 9,
            priceOfSlot: '₦20,000,000',
            annualEarning: '₦2,000,000'
        },
        {
            id: 3,
            title: 'Co-owned House',
            totalPrice: '₦100,000,000',
            noOfSlotAvailable: 5,
            totalNumberOfSlot: 9,
            priceOfSlot: '₦20,000,000',
            annualEarning: '₦2,000,000'
        },
        {
            id: 1,
            title: 'Co-owned House',
            totalPrice: '₦100,000,000',
            noOfSlotAvailable: 0,
            totalNumberOfSlot: 9,
            priceOfSlot: '₦20,000,000',
            annualEarning: '₦2,000,000'
        },
    ]

    return (
        <div className={`py-10`}>
            <div className='text-center '>
                <h1 className='text-2xl md:text-3xl lg:text-4xl font-bold py-2'>
                    Featured properties
                </h1>
                {/*<p className='text-sm'>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vel lobortis justo*/}
                {/*</p>*/}
                <div>
                    <div className="hidden md:flex pt-3 xl:gap-6 lg:gap-6 md:gap-3 justify-center">
                            <span
                                className="group cursor-pointer bg-white hover:text-green-300 featuredTab rounded-custom font-semibold px-4 py-2.5 relative">
                                Houses
                                {/*<div*/}
                                {/*    className="group-hover:opacity-100 toolTip w-max transition-opacity bg-white text-sm text-black px-4 py-2.5 rounded-md absolute left-1/2 -translate-x-1/2 translate-y-2.5 opacity-0 m">*/}
                                {/*    <div className="arrow-up"></div>*/}
                                {/*    8 Property*/}
                                {/*</div>*/}
                            </span>
                        <span
                            className="group cursor-pointer bg-white hover:text-green-300 featuredTab rounded-custom font-semibold px-4 py-2.5 relative">
                                Smart home
                            {/*<div*/}
                            {/*    className="group-hover:opacity-100 toolTip w-max transition-opacity bg-white text-sm text-black px-4 py-2.5 rounded-md absolute left-1/2 -translate-x-1/2 translate-y-2.5 opacity-0 m">*/}
                            {/*    <div className="arrow-up"></div>*/}
                            {/*    8 Property*/}
                            {/*</div>*/}
                            </span>
                        <span
                            className="group cursor-pointer bg-white hover:text-green-300 featuredTab rounded-custom font-semibold px-4 py-2.5 relative">
                                Apartments
                            {/*<div*/}
                            {/*    className="group-hover:opacity-100 toolTip w-max transition-opacity bg-white text-sm text-black px-4 py-2.5 rounded-md absolute left-1/2 -translate-x-1/2 translate-y-2.5 opacity-0 m">*/}
                            {/*    <div className="arrow-up"></div>*/}
                            {/*    8 Property*/}
                            {/*</div>*/}
                            </span>
                        <span
                            className="group cursor-pointer bg-white hover:text-green-300 featuredTab rounded-custom font-semibold px-4 py-2.5 relative">
                                Office
                            {/*<div*/}
                            {/*    className="group-hover:opacity-100 toolTip w-max transition-opacity bg-white text-sm text-black px-4 py-2.5 rounded-md absolute left-1/2 -translate-x-1/2 translate-y-2.5 opacity-0 m">*/}
                            {/*    <div className="arrow-up"></div>*/}
                            {/*    8 Property*/}
                            {/*</div>*/}
                            </span>
                        <span
                            className="group cursor-pointer bg-white hover:text-green-300 featuredTab rounded-custom font-semibold px-4 py-2.5 relative">
                                Villa
                            {/*<div*/}
                            {/*    className="group-hover:opacity-100 toolTip w-max transition-opacity bg-white text-sm text-black px-4 py-2.5 rounded-md absolute left-1/2 -translate-x-1/2 translate-y-2.5 opacity-0 m">*/}
                            {/*    <div className="arrow-up"></div>*/}
                            {/*    8 Property*/}
                            {/*</div>*/}
                            </span>
                        <span
                            className="group cursor-pointer bg-white hover:text-green-300 featuredTab rounded-custom font-semibold px-4 py-2.5 relative">
                                Bungalow
                            {/*<div*/}
                            {/*    className="group-hover:opacity-100 toolTip w-max transition-opacity bg-white text-sm text-black px-4 py-2.5 rounded-md absolute left-1/2 -translate-x-1/2 translate-y-2.5 opacity-0 m">*/}
                            {/*    <div className="arrow-up"></div>*/}
                            {/*    8 Property*/}
                            {/*</div>*/}
                            </span>
                    </div>
                </div>
            </div>
            <div className='featuredProperty lg:w-10/12 mx-auto !pb-24'>
                {Investment.map((investment, index) => {
                    return (
                        <div key={index}
                             className='rounded-3xl overflow-hidden xl:w-[285px] bg-white border border-custom-grey'>
                            <div className='relative'>
                                <img src={propertyImage} alt='investment' className='w-full'/>
                                <span
                                    className={`absolute uppercase bg-white top-2 font-semibold left-2 rounded-full text-xs py-1 px-2.5 ${investment.noOfSlotAvailable === 0 ? 'text-red-500' : 'text-green-600'}`}>
                                        {investment.noOfSlotAvailable === 0 ? 'Sold Out' : 'Active'}
                                        </span>
                            </div>
                            <div className='p-3'>
                                <h1 className='py-2'>{investment.title}</h1>
                                <p className='font-bold text-lg pb-2.5'>{investment.totalPrice}</p>
                                <div className='flex justify-between text-xs pb-1'>
                                    <span>No Of Slot Available:</span>
                                    <span
                                        className={`font-semibold ${investment.noOfSlotAvailable === 0 ? 'text-red-500' : 'text-green-600'}`}>
                                                {investment.noOfSlotAvailable === 0 ? 'Sold Out' : `${investment.noOfSlotAvailable} Slot Available`}
                                            </span>
                                </div>
                                <div className='flex justify-between gap-1 pb-1.5'>
                                    {Array.from({length: investment.totalNumberOfSlot}).map((_, index) => (
                                        <hr key={index}
                                            className={`h-2 w-8 rounded-full ${index < (investment.totalNumberOfSlot - investment.noOfSlotAvailable) ? 'bg-custom-darkgreen' : 'bg-custom'}`}/>
                                    ))}
                                </div>
                                <div className='flex justify-between text-xs pb-2.5'>
                                    <span>Price Per Slot:</span>
                                    <span className={`font-semibold`}>{investment.priceOfSlot}</span>
                                </div>
                                <div className='flex justify-between text-xs pb-6'>
                                    <span>Annual Earnings:</span>
                                    <span className={`font-semibold`}>{investment.annualEarning}</span>
                                </div>
                                <div className='flex justify-end'>
                                    <Link to={`/app/property/${investment.id}`}
                                          className='bg-custom-midnightgreen px-7 py-1 rounded-full text-white text-sm'>
                                        View
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <Footer />
        </div>

    )
}
export default Property