import React, { createContext, useContext, useEffect, useReducer } from 'react';
interface AuthState {
    token: string;
    user: string;
    userType: string | null;
}

interface Login {
    access_token: string;
}
type Action = { type: 'LOGIN'; payload: Login } | { type: 'LOGOUT' };


const initialState: AuthState = {
    token: localStorage.getItem('token') || '',
    user: localStorage.getItem('user') || '',
    userType: localStorage.getItem('userType') || null
};

const authReducer = (state: AuthState, action: Action): AuthState => {
    switch (action.type) {
        case 'LOGIN':
            console.log(action.payload)
            localStorage.setItem('token', action.payload.access_token);
            return { ...state, token: action.payload?.access_token };
        case 'LOGOUT':
            localStorage.removeItem('token');
            return { ...state, token: '' };
        default:
            return state;
    }
};

const AuthContext = createContext<{ state: AuthState; dispatchLogin: (user: Login) => void; logout: () => void }>({
    state: initialState,
    dispatchLogin: () => {},
    logout: () => {},
});

export const AuthProvider: React.FC<{children: any}> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        const token = localStorage.getItem('token');
        // const userType = localStorage.getItem('userType');
        if (token) {
            dispatch({ type: 'LOGIN', payload: {
                    access_token: token,
                } });
        }
    }, []);

    const dispatchlogin = (user: Login) => {
        dispatch({ type: 'LOGIN', payload: user });
    };

    const logout = () => {
        dispatch({ type: 'LOGOUT' });
    };

    return (
        <AuthContext.Provider value={{ state, dispatchLogin: dispatchlogin, logout }}>
            {children}
        </AuthContext.Provider>
    )
};

export const useAuth = () => useContext(AuthContext);
