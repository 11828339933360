import React from "react";
// import Navbar from "../Components/Layout/Navbar";
import CoownSignup from "../../Components/SignUp/CoOwn/CoownSignup";

function CoownRegister() {
    return (
        <div>
            {/*<Navbar />*/}
            <CoownSignup />
        </div>
    )
}

export default CoownRegister